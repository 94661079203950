import React from "react";
import "./aboutme.scss";
import html from "../../assets/html.svg";
import css3 from "../../assets/css-3.svg";
import javascript from "../../assets/javascript.svg";
import react from "../../assets/react.svg";
import redux from "../../assets/redux.svg";
import sass from "../../assets/sass.svg";
import typescript from "../../assets/typescript.svg";
import webpack from "../../assets/webpack.svg";
import style from "../../assets/style.svg";
import bootsrap from "../../assets/bootstrap.svg";
import git from "../../assets/git.svg";
import next from "../../assets/next.svg";
import tailwind from "../../assets/tailwind-css.svg";
import SkillCard from "./Components/SkillCard.jsx/SkillCard";
import { motion } from "framer-motion";

const Aboutme = () => {
  return (
    <div className="aboutme" id="aboutme">
      <motion.div
        className="wrapper"
        initial={{ opacity: 0.5 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
      >
        <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
          <div className="progress-container">
            <div className="progress-circle"></div>
            <div className="progress-stick"></div>
          </div>
          <div className="aboutme-info">
            <h2>INTRODUCTION</h2>
            <h1>Overview.</h1>
            <p>
              Hi, there My name is Ahmet , i am 25 years old. I am a Computer
              Engineer who loves his job. <br />
              I am actively developing projects in the Frontend side for 2
              years.
              <br /> I am hungry to learn new techonologies and programming
              languages so i am constantly improving my coding skills.
              <br />
              You can see my active skill set below also download my resume and
              contact with me.
            </p>
            <a href="cv.pdf" download="cv.pdf">
              Download Cv
            </a>
          </div>
        </div>
      </motion.div>
      <motion.div
        className="skill-container"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
      >
        <SkillCard photo={html} />
        <SkillCard photo={css3} />
        <SkillCard photo={bootsrap} />
        <SkillCard photo={sass} />
        <SkillCard photo={style} />
        <SkillCard photo={javascript} />
        <SkillCard photo={typescript} />
        <SkillCard photo={react} />
        <SkillCard title="next" photo={next} />
        <SkillCard photo={redux} />
        <SkillCard photo={webpack} />
        <SkillCard photo={tailwind} />
        <SkillCard photo={git} />
      </motion.div>
    </div>
  );
};

export default Aboutme;
