import React from "react";
import "./skillcard.scss";

const SkillCard = ({ photo, title }) => {
  return (
    <div className="skill-card">
      <div className="skill-img-container">
        <img
          style={
            title === "next"
              ? { backgroundColor: "white" }
              : { backgroundColor: "transparent" }
          }
          src={photo}
        ></img>
      </div>
    </div>
  );
};

export default SkillCard;
